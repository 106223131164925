<!--
 * @Author: diaowangtao
 * @Date: 2020-08-20 15:38:07
 * @Description: 投资策略页面
-->
<template>
  <div class="invest">
    <my-banner :bg_banner="bg_banner" v-if="bg_banner"></my-banner>
    <div class="w1200">
      <my-menu :menu="menu" :active="active" @changeActive="changeActive" class="left menu"></my-menu>
      <div class="invest_r right">
        <!-- <div v-show="active==0">
          <div
            class="news hand"
            v-for="(item,index) in news0"
            :key="index"
            @click="$router.push({ name: 'news', query: { id: item.id } })"
            :id="item.id"
            htmdEvt="click_news"
          >
            <img :src="item.image" alt class="left" />
            <div class="right news_r">
              <p class="news_title">{{item.title|sLang}}</p>
              <p class="news_content">{{item.digest|sLang}}</p>
              <p class="news_time">{{item.time|sLang}}</p>
            </div>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange_0"
            :page-size="5"
            layout="total, prev, pager, next"
            :total="totalSize_0"
            v-if="totalPage_0>1"
          ></el-pagination>
        </div> -->
        <div v-show="active==0">
          <div
            class="news hand"
            v-for="(item,index) in news"
            :key="index"
            @click="$router.push({ name: 'news', query: { id: item.id } })"
            :id="item.id"
            htmdEvt="click_news"
          >
            <img :src="item.image" alt class="left" />
            <div class="right news_r">
              <p class="news_title">{{item.title|sLang}}</p>
              <p class="news_content">{{item.digest|sLang}}</p>
              <p class="news_time">{{item.time|sLang}}</p>
            </div>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange_1"
            :page-size="5"
            layout="total, prev, pager, next"
            :total="totalSize_1"
            v-if="totalPage_1>1 && !showFlag1"
          ></el-pagination>
          <no-data :showFlag="showFlag1" :contentProps="contentProps"></no-data>
        </div>
        <div v-show="active==1">
          <div
            class="outlook"
            v-for="(item ,index) in look"
            :key="index"
            :class="[item.pdf?'hand':'']"
            :id="item.id"
            :htmdEvt="[item.pdf?'click_news':'']"
          >
            <img
              :src="item.image"
              :class="[index%2 != 1?'left':'right']"
              @click="goToPage(item.pdf)"
            />
            <div class="outlook_r" :class="[index%2 != 1?'right':'left']">
              <p
                class="outlook_title"
                :class="[index%2 != 1?'':'title_r']"
                @click="goToPage(item.pdf)"
              >{{item.title|sLang}}</p>
              <p
                class="outlook_content"
                :class="[index%2 != 1?'':'title_r']"
                @click="goToPage(item.pdf)"
                v-html="$options.filters.sLang(item.content)"
              ></p>
              <my-button
                :text="$options.filters.sLang('查看详情')"
                :class="[index%2 != 1?'':'right']"
                @goToPage="goToPage(item.pdf)"
                class="outlook_button"
                v-if="item.pdf"
              ></my-button>
            </div>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange_2"
            :page-size="3"
            layout="total, prev, pager, next"
            :total="totalSize_2"
            v-if="totalPage_2>1 && !showFlag2"
          ></el-pagination>
          <no-data :showFlag="showFlag2" :contentProps="contentProps"></no-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myBanner from '@/components/myBanner/index.vue'
import myMenu from '@/components/myMenu/index.vue'
import myButton from '@/components/myButton/index.vue'
import noData from '@/components/noData/noData.vue'
import {
  getForum,
  getInvestStrategy,
  getSpecialTopic,
  getInvestOutlook,
} from '@/api/investStrategy'
export default {
  name: 'invest',
  components: {
    myMenu: myMenu,
    myBanner: myBanner,
    myButton: myButton,
    noData
  },
  data() {
    return {
      active: 0,
      bg_banner: '',
      menu: ['专题文章', '投资展望'],
      //menu: ['投资策略', '专题文章', '投资展望'],
      page_0: 1,
      page_1: 1,
      page_2: 1,
      totalPage_0: 0,
      totalSize_0: 0,
      totalPage_1: 0, //总页数
      totalSize_1: 0, //总条数
      totalPage_2: 0,
      totalSize_2: 0,
      news0: [],//投资策略
      news: [],//专题文章
      look: [],//投资展望
      info: [],
      showFlag1:false,//暂无数据是否显示传值
      showFlag2:false,//暂无数据是否显示传值
      contentProps:'暂无数据',
      flag: true,
    }
  },
  created() {
    this.active = sessionStorage.getItem('investTab') ? Number(sessionStorage.getItem('investTab')) : 0
    getInvestStrategy().then((res) => {
      this.bg_banner = res.coverList[1].oldUrl
    })
    getInvestOutlook()
      .then((res) => {
        this.info = res.children.map((item) => {
          return {
            forumId: item.id,
            image: item?.coverList?.[1]?.oldUrl || '',
          }
        })
      })
      .then(() => {
        this.getLook(this.page_2)
      })
    //this.getNews0(this.page_0)
    this.getNews(this.page_1)
  },
  updated(){
    if ( this.flag) {
        let anchor = this.$route.query.anchor
        if( !!anchor ){
          this.changeActive(anchor)
        }
        this.flag = false;
    }
  },
  methods: {
    changeActive(i) {
      this.active = i
    },
    goToPage(e) {
      //this.$router.push({ name: 'news', query: { id: id } })
      if (e) {
        //   window.open(pdf)
        let { href } = this.$router.resolve({
          name: 'news-pdf',
          query: { src: e },
        })
        window.open(href, '_blank')
      }
    },
    // handleCurrentChange_0(val) {
    //   this.getNews0(`${val}`)
    // },
    handleCurrentChange_1(val) {
      this.getNews(`${val}`)
    },
    handleCurrentChange_2(val) {
      this.getLook(`${val}`)
    },
    getNews(pageNo) {
      getSpecialTopic({ pageNo: pageNo, pageSize: 5 }).then((res) => {
        //console.log(res)
        //无数据时样式 展示
        if(res.status == '1000' || res.status == '4000'){
          this.showFlag1 = true
        }else{
          this.totalPage_1 = res.totalPage
          this.totalSize_1 = res.totalSize
          this.news = res.list.map((item) => ({
            id: item.id,
            time: item.publishTime.slice(0, 16),
            title: item.article.title,
            digest: item.article.digest,
            image: item.article?.coverList?.[1]?.oldUrl || '',
          }))
        }
        
      })
    },
    // getNews0(pageNo) {
    //   getSpecialTopic({ pageNo: pageNo, pageSize: 5 }).then((res) => {
    //     //console.log(res)
    //     this.totalPage_0 = res.totalPage
    //     this.totalSize_0 = res.totalSize
    //     this.news0 = res.list.map((item) => ({
    //       id: item.id,
    //       time: item.publishTime.slice(0, 16),
    //       title: item.article.title,
    //       digest: item.article.digest,
    //       image: item.article?.coverList?.[1]?.oldUrl || '',
    //     }))
    //   })
    // },
    async getLook(pageNo) {
      await getForum({
        pageNo: pageNo,
        pageSize: 3,
        forumId: this.info[0].forumId,
      }).then((res) => {
        console.log(res)
        //无数据时样式 展示
        if(res.status == '1000' || res.status == '4000'){
          this.showFlag2 = true
        }else{
          this.totalPage_2 = res.totalPage
          this.totalSize_2 = res.totalSize
          this.look = res.list.map((item) => ({
            id: item.id,
            forumId: this.info[0].forumId,
            title: item.article.title,
            image: item.article?.coverList?.[1]?.oldUrl || '',
            pdf: item.article?.attachmentList?.[0]?.url || '',
            content: item.article.content,
          }))
        }
      })
    },
  },
  watch:{
    active(newVlaue,oldValue){
      sessionStorage.setItem('investTab',newVlaue)
    }
  },
  beforeRouteLeave (to, from, next) {
      // 导航离开该组件的对应路由时调用
      // 可以访问组件实例 `this`
      sessionStorage.removeItem('investTab')
      next()
  },
}
</script>

<style lang='scss'>
.invest {
  .w1200 {
    overflow: hidden;
    .menu {
      margin-top: 60px;
    }
    .invest_r {
      width: 920px;
      margin-bottom: 100px;
      padding-top: 10px;
      margin-top: 50px;
      .news {
        width: 910px;
        height: 150px;
        margin-bottom: 20px;

        img {
          width: 200px;
          height: 150px;
          border-radius: 2px;
        }
        .news_r {
          width: 690px;
          padding-right: 10px;
          .news_title {
            height: 46px;
            line-height: 46px;
            font-size: 18px;
            color: #303a51;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .news_content {
            color: #575c6d;
            text-align: justify;
            line-height: 22px;
            height: 66px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .news_time {
            height: 36px;
            line-height: 36px;
            color: #8d909b;
          }
        }
      }
      .news:hover {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
      }
      .outlook {
        overflow: hidden;
        margin-bottom: 50px;
        img {
          width: 400px;
          height: 225px;
          border-radius: 2px;
        }
        .outlook_r {
          width: 500px;
          .outlook_title {
            height: 46px;
            line-height: 46px;
            font-size: 18px;
            color: #303a51;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .outlook_content {
            color: #575c6d;
            text-align: justify;
            line-height: 22px;
            height: 132px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
          }
          .outlook_button {
            margin-top: 15px;
          }
          .title_r {
            text-align: right;
          }
        }
      }
    }
  }
  .el-pagination {
    text-align: center;
    margin-top: 50px;
    font-weight: 400;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    font-family: HelveticaNeue;
    margin: 0 5px;
    border: 1px #e5e5e5 solid;
    color: #303a51;
    min-width: 30px;
    border-radius: 4px;
    background: none;
  }
  .el-pagination.is-background .el-pager li:hover {
    color: #303a51;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2074cc;
    color: #fff;
    border: 1px #2074cc solid;
  }
}
</style>